@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
  border-radius: 0px 5px 5px 0px !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
  border-radius: 5px 0px 0px 5px !important;
}

.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  z-index: 9 !important;
  background: #fff !important;
  min-width: 45px !important;
  min-height: 80px !important;
  opacity: 1;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.react-multiple-carousel__arrow::before {
  font-size: 25px !important;
  font-weight: 700;
  color: #090909 !important;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
}

.react-multiple-carousel__arrow:hover {
  background: #fff !important;
}

.close_btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
}

.reg-card {
  margin-bottom: 1.563rem;
  background-color: #f8faff;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0rem solid transparent;
  border-radius: 0.375rem;
  height: calc(100% - 30px);
  border: 1px solid #e6e6e6;
}
.reg-header {
  border-color: #e6e6e6;
  position: relative;
  background: #f8faff;
  padding: 0.8rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}
.reg-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}
.light-blue {
  cursor: pointer;
  background-color: #eaeaff;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
