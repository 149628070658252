@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    zoom: 95%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.icon-style {
    width: 22px;
    height: 22px;
    background-color: green;
    color: #fff;
    text-align: center;
    padding: 4px;
    border-radius: 50px;
    font-size: 15px;
}

.viewCartScroll {
    height: 250px;
    overflow-y: auto;
}

input:where(:not([type])):focus, [type='text']:focus {
    --tw-ring-color: none;
    border: 0 !important;
    border-radius: unset;
}

.category-list-container {
    width: 100%;
}

.category-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap; /* Wrap items to new line when they overflow */
}

.category-list li {
    flex: 0 0 auto; /* Equal width for all list items */
    padding: 10px;
    /*margin-right: 10px;*/
    border-radius: 5px;
}

.jiMOeR .wrapper {
    border: 0 !important;
    border-radius: unset;
}

.search-icon {
    display: none;
}

.jiMOeR .wrapper:hover {
    border: 0 !important;
    border-radius: unset;
    box-shadow: unset !important;
}

.jiMOeR .wrapper:focus-within {
    box-shadow: unset !important;
}

.input-container {
    position: relative;
}

/* Style for the input field */
.input-field {
    padding-right: 30px; /* Adjust padding to accommodate the icon */
    height: 40px; /* Adjust height as needed */

}

/* Style for the icon */
.input-icon {
    position: absolute;
    right: 0; /* Adjust left position to align the icon */
    top: 50%;
    transform: translateY(-50%);
    color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
}

.text-field {
    white-space: nowrap; /* Ensures that the text stays on a single line */
    overflow: hidden; /* Hides any overflowing text */
    text-overflow: ellipsis; /* Displays ellipsis (...) for overflowing text */
}
.custom-select .Select-menu-outer {
    max-height: 200px; /* Set maximum height of the menu list */
    overflow-y: auto; /* Enable vertical scrolling if needed */
}

